import styled, { css } from 'styled-components';

export const ReviewCard = styled.div`
  background-color: var(--colors-bg-light);
  cursor: pointer;
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 3.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3.5rem;
  position: relative;
  transition-duration: 200ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;

  &:hover {
    /* TODO: Abstract RGBA value (copied from ActionBar) */
    box-shadow: 0 0.25rem 1rem 0 rgba(27, 31, 31, 0.14);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  h5 {
    word-break: break-all;
  }
`;

export const ReviewCardPreview = styled.div`
  background-color: var(--colors-bg-default);
  flex-shrink: 0;
  height: 11.125rem;
  margin-right: 1.5rem;
  position: relative;
  width: 8.125rem;
  display: flex;

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  svg {
    color: var(--colors-text-muted);
    margin: auto;
  }
`;

export const ReviewCardAction = styled.div`
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
`;
