import styled, { css } from 'styled-components';
import { v } from '@gohypergiant/scoa-smds';

export const Toast = styled.div`
  --toastHeight: 4.5rem;

  align-items: center;
  background-color: white;
  display: flex;
  font-size: 1.5rem;
  height: var(--toastHeight);
  justify-content: space-between;
  letter-spacing: 0.067em;
  padding-left: calc((100vw - var(--maxWidth)) / 2 + var(--appPadding));
  padding-right: calc((100vw - var(--maxWidth)) / 2 + var(--appPadding));
  position: absolute;
  width: 100%;
  z-index: 10;

  ${({ variant }) =>
    variant &&
    css`
      background-color: ${v('colors.functional.' + variant)};
    `}

  svg {
    display: block;
    cursor: pointer;
  }
`;
