import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@iconify/react';
import arrowBackOutline from '@iconify/icons-ion/arrow-back-outline';

export const BackLink = (props) => (
  <Link {...props}>
    <h6
      style={{
        alignItems: 'center',
        color: 'var(--colors-text-dark)',
        display: 'flex',
        fontWeight: 'bold',
      }}
    >
      <Icon
        icon={arrowBackOutline}
        style={{ marginRight: '0.75rem' }}
        width="1rem"
      />
      Back
    </h6>
  </Link>
);
