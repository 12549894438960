import React, { Fragment, useCallback, useState } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useInterval } from 'hooks/interval';
import log from 'utils/log';
import prettyMs from 'pretty-ms';
import {
  ActionBar,
  ProgressIndicator,
  Step,
  Loading,
  Scanning,
  TopBar,
} from '../../molecules';
import { Toast } from '../../atoms';

const POLL_PACKAGE = gql`
  query getDocumentPackage($id: ID!) {
    documentPackage(id: $id) {
      id
      status
      documents {
        id
        fileName
        type
        status
      }
    }
  }
`;

const FinishedMessage = styled.div`
  bottom: 10rem;
  color: #fff;
  font-size: 1.5rem;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
`;

const Timer = () => {
  const [time, setTime] = useState(0);

  const tick = useCallback(() => setTime((t) => t + 1000), []);

  useInterval(tick, 1000);

  return (
    <div style={{ fontSize: '0.875rem' }}>
      Elapsed time: &nbsp;
      <b>
        {prettyMs(time, {
          secondsDecimalDigits: 0,
          colonNotation: true,
        })}
      </b>
    </div>
  );
};

const DocumentProgress = ({ isComplete }) => {
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          marginBottom: '0.75rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>Scanning documents</div>
        {!isComplete && <Timer />}
        {isComplete && <div style={{ fontSize: '0.875rem' }}>Finished</div>}
      </div>
      <Loading />
    </div>
  );
};

export const ScanningPage = ({ match, history }) => {
  const { packageId } = match.params;

  const { data, error: requestError } = useQuery(POLL_PACKAGE, {
    pollInterval: process.env.POLL_INTERVAL || 30000,
    // TODO: revisit fetchPolicy
    fetchPolicy: 'no-cache',
    variables: {
      id: packageId,
    },
  });

  if (requestError) console.log('Error', requestError);

  const documentPackageStatus = get(data, 'documentPackage.status');

  const documentPackageFailed = documentPackageStatus === 'FAILED';

  const isComplete =
    documentPackageFailed || documentPackageStatus === 'COMPLETE';

  log('isComplete', isComplete);
  console.log('isComplete', isComplete);

  if (isComplete && !documentPackageFailed) {
    // NOTE: this timeout is not technically necessary but does allow us to notify
    // the user that processing is complete for both docs before we redirect them
    setTimeout(() => {
      history.push(`/${packageId}/review`);
    }, 5000);
  }

  return (
    <Fragment>
      <TopBar />

      <main>
        <ProgressIndicator style={{ color: 'white' }}>
          <Step check>Upload</Step>
          <Step active>Scan</Step>
          <Step>Review</Step>
          <Step>Export</Step>
        </ProgressIndicator>

        <Scanning />

        {documentPackageFailed && (
          <Toast variant="danger">
            The server has encountered an error while processing this package.
            It will try again. If it does not finish within 10 minutes try a new
            upload or contact support.
          </Toast>
        )}

        {requestError && (
          <Toast variant="danger">
            There was an error contacting the server. Please try again.
          </Toast>
        )}

        {isComplete && (
          <FinishedMessage>
            Scanning finished.
            {!documentPackageFailed && 'Progressing to review in 5 seconds.'}
          </FinishedMessage>
        )}

        <ActionBar>
          {/* TODO: make this indeterminate */}
          <DocumentProgress isComplete={isComplete} />
        </ActionBar>
      </main>
    </Fragment>
  );
};

export default ScanningPage;
