import React from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from 'hooks/user';

export const AuthenticatedRoutes = ({ children }) => {
  const { user } = useUser();

  if (!user) {
    return <Redirect to="/login" />;
  }

  return children;
};
