import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './app';
import reportWebVitals from './vitals';

import { defaultTheme, insertCustomProperties } from '@gohypergiant/scoa-smds';

if (!document.querySelector('[data-css-variables]')) {
  insertCustomProperties(defaultTheme);
}

Amplify.configure({
  Auth: {
    region: process.env.AUTH_REGION,
    identityPoolId: process.env.AUTH_IDENTITYPOOL_ID,
    userPoolId: process.env.AUTH_USERPOOL_ID,
    userPoolWebClientId: process.env.AUTH_USERPOOL_CLIENTID,
    mandatorySignIn: true,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production') {
  // TODO: wrap in a try/catch
  localStorage.setItem('debug', '*');
}

reportWebVitals(console.log);
