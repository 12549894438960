import React from 'react';
import styled, { css } from 'styled-components';

import { defaultTheme } from '@gohypergiant/scoa-smds';

function getResponsiveWidth(width) {
  let styles = ``;

  for (const breakpoint in width) {
    // Ensure key passed to component is a supported breakpoint
    if (defaultTheme.breakpoints[breakpoint] !== undefined) {
      styles += `
        @media (min-width: ${defaultTheme.breakpoints[breakpoint]}) {
          display: block;
          width: calc(${width[breakpoint]});
        }
      `;
    } else if (breakpoint === 'base') {
      styles += `
        display: block;
        width: calc(${width[breakpoint]});
      `;
    } else {
      // eslint-disable-next-line no-console
      console.warn(`'${width[breakpoint]}' is not supported by LayoutItem`);
    }
  }

  return styles;
}

export const StyledLayoutItem = styled.div`
  flex-shrink: 0;
  width: 100%;

  ${({ width }) => getResponsiveWidth(width)}
`;

export const LayoutItem = ({ children, ...props }) => {
  return <StyledLayoutItem {...props}>{children}</StyledLayoutItem>;
};

export const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${({ gutterWidth }) =>
    gutterWidth &&
    css`
      margin-left: calc(${gutterWidth} * -1);

      > div {
        padding-left: calc(${gutterWidth});
      }
    `}
`;
