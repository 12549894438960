import styled from 'styled-components';

import { v } from '@gohypergiant/scoa-smds';
import { Logo } from '../../atoms';

export const StyledTopBar = styled.div`
  background-color: #d9d9d9;
  display: flex;
  height: 5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export const LogoWrapper = styled.div`
  background-color: ${v('colors.black')};
  width: 20rem;
  display: flex;
  color: white;
  height: 100%;
`;

export const StyledLogo = styled(Logo)`
  height: 2.5rem;
  display: block;
  margin: auto;

  @media print {
    margin: 0;

    path {
      fill: black;
    }
  }
`;

export const UserWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  height: 100%;
  justify-content: center;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  position: relative;
  width: 18.5rem;
  cursor: pointer;

  &::before {
    background-color: currentColor;
    content: '';
    height: 1.5rem;
    left: 0;
    position: absolute;
    top: 1.75rem;
    width: 1px;
  }

  @media print {
    display: none;
  }
`;

export const Username = styled.div`
  padding-left: 1.5rem;
  color: #2c3838;
`;

export const Logout = styled.div`
  align-items: center;
  background-color: #edf1f1;
  border: 1px solid #c5c5c5;
  border-top-width: 0;
  box-shadow: 0 0.5rem 1rem 0 rgba(27, 31, 31, 0.33);
  display: flex;
  font-size: 0.875rem;
  height: 4.5rem;
  justify-content: space-between;
  left: -1px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: absolute;
  top: 5rem;
  transition-duration: 100ms;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  width: calc(100% + 1px);
  z-index: 11;
`;
