import styled, { css } from 'styled-components';

import { v } from '@gohypergiant/scoa-smds';

/* TODO: factor out api.iconify.design references and associated invert() hacks */

export const ProgressIndicator = styled.div`
  display: flex;
  height: 4.25rem;
  justify-content: center;
  left: -2rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  position: relative;
  width: 100%;
  z-index: 5;
  transition-duration: 800ms;
  transition-property: color;
  transition-timing-function: ease-in-out;

  @media print {
    display: none;
  }
`;

export const Step = styled.div`
  --stepDiameter: 2.25rem;
  --stepSpacing: 8rem;
  --stepColor: white;

  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-left: 6rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 8rem;

  &::before {
    background-color: var(--stepColor);
    border-radius: 50%;
    border: 1px solid #777777;
    content: '';
    height: var(--stepDiameter);
    left: 50%;
    margin-left: -1.125rem;
    position: absolute;
    top: 2rem;
    width: var(--stepDiameter);
    z-index: 1;
  }

  &:not(:last-of-type)::after {
    background-color: #777777;
    bottom: 0;
    content: '';
    height: 1px;
    left: 50%;
    margin-bottom: calc(var(--stepDiameter) / 2 - 1px);
    position: absolute;
    width: calc(100% + 6rem);
    z-index: 0;
  }

  ${(props) =>
    props.active &&
    css`
      --stepColor: ${v('colors.teal')};

      font-size: 0.875rem;
      font-weight: 600;
    `}

  ${(props) =>
    props.check &&
    css`
      /* stylelint-disable-next-line no-duplicate-selectors */
      &::before {
        background: url('https://api.iconify.design/ion:checkmark.svg')
          no-repeat center center / 1.5rem;
        background-color: white;
        filter: invert();
      }
    `}

    ${(props) =>
    props.complete &&
    css`
      /* stylelint-disable-next-line no-duplicate-selectors */
      &::before {
        background: url('https://api.iconify.design/ion:checkmark.svg')
          no-repeat center center / 1.5rem;
        background-color: #a31e64; /* inverted colors.functional.success */
        filter: invert();
      }
    `}
`;

export const StepCount = styled.div`
  bottom: 0;
  color: white;
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  height: var(--stepDiameter);
  left: 50%;
  line-height: calc(
    var(--stepDiameter) - 2px
  ); /* ^ nudge to optically center */
  margin-left: calc(var(--stepDiameter) / -2);
  position: absolute;
  text-align: center;
  text-indent: 1px;
  width: var(--stepDiameter);
  z-index: 1;
`;
