import styled from 'styled-components';

import { Badge as SMDSBadge } from '@gohypergiant/scoa-smds';

export const Badge = styled(SMDSBadge)`
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;

  @media print {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;
