import React, { createContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v } from '@gohypergiant/scoa-smds';
import { Auth } from 'aws-amplify';

const StyledLoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${v('colors.bg.default')};
  display: grid;
  align-items: center;
  justify-items: center;
  grid-row-gap: ${v('space.md')};
`;

// NOTE: we catch errors in the context of where they are called
// NOTE: this is exported so we can use it in our Apollo links
// NOTE: returns a promise
export function signOut() {
  return Auth.signOut();
}

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function login(username, password) {
    return Auth.signIn(username.toLowerCase(), password).then((user) => {
      setUser(() => user);
      return user;
    });
  }

  function logout() {
    return signOut().then((data) => {
      setUser(() => null);
      return data;
    });
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(() => user);
      })
      .catch(() => {
        setUser(() => null);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  const values = useMemo(() => ({ user, login, logout }), [user]);

  if (loading) {
    return (
      <StyledLoadingContainer>
        <h3>Authenticating...</h3>
      </StyledLoadingContainer>
    );
  }

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
