import styled from 'styled-components';

export const Message = styled.div`
  animation-delay: 400ms;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  color: var(--colors-text-muted);
  opacity: 0;
  padding-top: 8rem;
  text-align: center;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
