import React, { useEffect } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Spinner } from '@gohypergiant/scoa-smds';

const StyledLoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoadingGrid = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  justify-items: center;
`;

const CREATE_PACKAGE = gql`
  mutation createDocumentPackage {
    createDocumentPackage {
      id
    }
  }
`;

const InitPage = () => {
  const [createPackage, { error, data, loading }] = useMutation(CREATE_PACKAGE);

  useEffect(() => {
    createPackage();
    // NOTE: createPackage is technically pure so we can ignore eslint warning
  }, []);

  const packageId = get(data, 'createDocumentPackage.id');

  if (error) {
    console.log('error', error);
    return null;
  }

  // NOTE: this is so fast we can potentially just render null
  if (loading || !packageId) {
    return (
      <StyledLoadingContainer>
        <StyledLoadingGrid>
          <Spinner size="md" />
          <h3>Creating Document Package...</h3>
        </StyledLoadingGrid>
      </StyledLoadingContainer>
    );
  }

  return <Redirect to={`/${packageId}/upload`} />;
};

export default InitPage;
