import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { get } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { Button, Spinner } from '@gohypergiant/scoa-smds';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import documentOutlineIcon from '@iconify/icons-ion/document-outline';

import Icon from '@iconify/react';
import { DocumentsOutlineIcon } from '../../atoms';
import { FileUploadWrapper, FileCard } from '../../molecules';

const CREATE_DOCUMENT = gql`
  mutation createDocument($input: DocumentInput) {
    createDocument(input: $input) {
      uploadUrl
      document {
        id
        fileName
        status
      }
    }
  }
`;

const UPDATE_DOCUMENT_STATUS = gql`
  mutation updateDocumentStatus($input: DocumentStatusInput) {
    updateDocumentStatus(input: $input) {
      id
      fileName
      status
    }
  }
`;

const uploadDocument = async ({ file, signedUrl }) => {
  const res = await fetch(signedUrl, {
    method: 'PUT',
    body: file,
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
};

const FileDropError = ({ resetErrorBoundary }) => {
  return (
    <div>
      <p>There was an error processing your upload. Please try again.</p>
      <Button onClick={resetErrorBoundary}>Retry Upload</Button>
    </div>
  );
};
const FileDropInner = ({ packageId, onUpload, existingDocuments = [] }) => {
  const handleError = useErrorHandler();

  const [
    createDocument,
    {
      loading: createDocumentLoading,
      error: createDocumentError,
      // data: createDocumentData,
    },
  ] = useMutation(CREATE_DOCUMENT);

  const [
    updateDocument,
    { loading: updateDocumentLoading, error: updateDocumentError },
  ] = useMutation(UPDATE_DOCUMENT_STATUS);

  // TODO: handle this in the UI?
  if (createDocumentError) {
    console.log(createDocumentError);
  }

  // TODO: handle this in the UI?
  if (updateDocumentError) {
    console.log(createDocumentError);
  }

  const isLoading = createDocumentLoading || updateDocumentLoading;

  return (
    <Fragment>
      <Dropzone
        onDropAccepted={async (files) => {
          try {
            const uploadPromises = files.map(async (file) => {
              const { data: createDocumentData } = await createDocument({
                variables: {
                  input: {
                    documentPackageId: packageId,
                    fileName: file.name,
                    type: 'USER_UPLOADED',
                  },
                },
              });
              const documentId = get(
                createDocumentData,
                'createDocument.document.id'
              );
              const signedUrl = get(
                createDocumentData,
                'createDocument.uploadUrl'
              );
              await uploadDocument({ file, signedUrl });
              return updateDocument({
                variables: {
                  input: {
                    documentId,
                    status: 'UPLOADED',
                  },
                },
              });
            });
            await Promise.all(uploadPromises);
            onUpload();
          } catch (err) {
            console.log('UPLOAD ERROR', err);
            handleError(err);
          }
        }}
        accept="application/pdf"
        multiple
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <FileUploadWrapper
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            {isLoading && <Spinner size="md" />}

            {!isLoading && (
              <Fragment>
                <DocumentsOutlineIcon />

                <h4 style={{ color: 'inherit' }}>
                  {!isDragReject
                    ? 'Select File to Upload'
                    : 'Unsupported File type'}
                </h4>
                <h6>{!isDragReject && 'Or Drag and Drop'}</h6>
                <input {...getInputProps()} />
              </Fragment>
            )}
          </FileUploadWrapper>
        )}
      </Dropzone>
      {existingDocuments.map((doc) => (
        <FileCard key={doc.id}>
          <Icon
            icon={documentOutlineIcon}
            width="3rem"
            style={{ marginRight: '0.75rem' }}
          />
          {doc.fileName}
        </FileCard>
      ))}
    </Fragment>
  );
};

export const FileDrop = ({ packageId, onUpload, existingDocuments }) => {
  return (
    <ErrorBoundary FallbackComponent={FileDropError}>
      <FileDropInner
        packageId={packageId}
        onUpload={onUpload}
        existingDocuments={existingDocuments}
      />
    </ErrorBoundary>
  );
};
