import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import lockClosedOutlineIcon from '@iconify/icons-ion/lock-closed-outline';
import mailOutlineIcon from '@iconify/icons-ion/mail-outline';
import { Input, Button } from '@gohypergiant/scoa-smds';
import { useUser } from 'hooks/user';

import {
  Error,
  Modal,
  ModalBody,
  ModalHeader,
  StyledBackground,
  StyledLoginWrapper,
  StyledLogo,
} from './styles';

const Form = () => {
  const history = useHistory();
  const handleError = useErrorHandler();
  const { login, user } = useUser();
  const [submitting, setSubmitting] = useState(false);

  if (user) {
    return <Redirect to="/" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmitting(() => true);

    const data = new FormData(e.target);

    login(data.get('email'), data.get('password'))
      .then(() => {
        history.push('/');
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <form method="post" onSubmit={onSubmit}>
      <div style={{ marginBottom: '1.5rem' }}>
        <Input
          labelIcon={mailOutlineIcon}
          name="email"
          placeholder="Email"
          type="email"
        />
      </div>

      <div style={{ marginBottom: '2.5rem' }}>
        <Input
          labelIcon={lockClosedOutlineIcon}
          name="password"
          placeholder="Password"
          type="password"
        />
      </div>

      <Button disabled={submitting} stretched={true} type="submit">
        {submitting ? 'Authenticating...' : 'Login'}
      </Button>
    </form>
  );
};

const FormError = ({ error, resetErrorBoundary }) => {
  let errorMessage = 'User and/or password is not found. Please try again.';

  switch (error.code) {
    case 'InvalidParameterException':
      break;
    default:
      errorMessage = error.message;
  }

  console.log(error);

  useEffect(() => {
    const t = setTimeout(resetErrorBoundary, 2000);

    return () => {
      clearTimeout(t);
    };
  }, []);

  return <Error>{errorMessage}</Error>;
};

const Login = () => {
  return (
    <StyledLoginWrapper>
      <StyledBackground>
        <video
          muted
          autoPlay
          loop
          playsInline
          src="https://doctr-cdn.vercel.app/shipping-1080-lq.mp4"
          type="video/mp4"
        />
      </StyledBackground>

      <Modal>
        <ModalHeader>
          <StyledLogo />
        </ModalHeader>

        <ModalBody>
          <ErrorBoundary FallbackComponent={FormError}>
            <Form />
          </ErrorBoundary>
        </ModalBody>
      </Modal>
    </StyledLoginWrapper>
  );
};

export default Login;
