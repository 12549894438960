import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { DocumentsOutlineIcon } from '../../atoms';
import { FileUploadWrapper } from './styles';

export * from './styles';

export const FileUpload = () => {
  const onDrop = useCallback((acceptedFiles) => {
    // handleAcceptedFiles
    console.log('the accepted files are', acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <FileUploadWrapper
      {...getRootProps()}
      isDragActive={isDragActive}
      // hasFilesStaged={files[name]}
    >
      <DocumentsOutlineIcon />

      <h4>Select File to Upload</h4>
      <h6>Or Drag and Drop</h6>
      <input {...getInputProps()} accept="application/pdf" />
    </FileUploadWrapper>
  );
};
