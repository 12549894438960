import styled, { css } from 'styled-components';

export const FileUploadWrapper = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 17.875rem; /* 286px @ 16px rem per design ¯\_(ツ)_/¯ */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
  transition-duration: 400ms;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  svg {
    display: block;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
  }

  &:hover {
    background-color: #d9d9d9;
  }

  ${({ isDragActive, isDragReject }) =>
    isDragActive &&
    !isDragReject &&
    css`
      background-color: #d9d9d9;
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23f6675b' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
      color: #f6675b;

      h6 {
        color: inherit !important;
      }
    `}

  ${({ hasFilesStaged }) =>
    hasFilesStaged &&
    css`
      /* placeholder state */
    `}
`;
