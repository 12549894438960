import React from 'react';
import styled, { keyframes } from 'styled-components';

// NOTE: unicorn math
const loop = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(333%);
  }
`;

export const StyledLoading = styled.div`
  height: 10px;
  width: 100%;
  background-color: #eff0f0;
  position: relative;
  overflow: hidden;

  &::after {
    left: 0;
    top: 0;
    position: absolute;
    content: '';
    width: 33%;
    height: 100%;
    background-image: linear-gradient(90deg, #cbf6f8 0%, #9ceaef 100%);
    animation-name: ${loop};
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-fill-mode: none;
    transform: translateX(0);
    will-change: transform;
  }
`;

export const Loading = () => {
  return <StyledLoading></StyledLoading>;
};
