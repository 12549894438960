import { get } from 'lodash';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { Auth } from 'aws-amplify';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({ uri: process.env.API_URL });

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // TODO: logout function
  }
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const userSession = await Auth.currentSession();
  const authorization = get(userSession, 'idToken.jwtToken', null);

  operation.setContext({
    headers: {
      authorization,
    },
  });

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items(_, { args, toReference }) {
            return toReference({
              __typename: 'DocumentReviewItem',
              id: args.id,
            });
          },
        },
      },
    },
  }),
  link: from([authMiddleware, logoutLink, httpLink]),
});
