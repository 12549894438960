import styled from 'styled-components';

export const SideSheet = styled.div`
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(27, 31, 31, 0.14);
  height: calc(100% - 5rem);
  padding: 2.625rem;
  position: fixed;
  right: 0;
  top: 5rem; /* TODO: use var to derive offset from TopBar component */
  width: 33rem;
  z-index: 10;
  overflow: scroll;
`;
