import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useImage } from 'react-image';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import { Spinner } from '@gohypergiant/scoa-smds';
import Icon from '@iconify/react';
import documentOutlineIcon from '@iconify/icons-ion/document-outline';

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ImageError = () => {
  return (
    <StyledContainer>
      <Icon icon={documentOutlineIcon} width="2.4rem" />
    </StyledContainer>
  );
};

const ImageLoading = () => {
  return (
    <StyledContainer>
      <Spinner size="sm" />
    </StyledContainer>
  );
};

const ImageComponent = ({ src: passedSrc, ...props }) => {
  const handleError = useErrorHandler();

  const { src, error } = useImage({
    srcList: passedSrc,
  });

  if (error) {
    handleError(error);
  }

  return <img src={src} {...props} />;
};

export const Image = ({ src, ...props }) => {
  return (
    <ErrorBoundary fallback={<ImageError />}>
      <Suspense fallback={<ImageLoading />}>
        {src && <ImageComponent src={src} {...props} />}
      </Suspense>
    </ErrorBoundary>
  );
};
