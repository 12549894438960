import React from 'react';

export const DocumentsOutlineIcon = () => (
  <svg
    width="58"
    height="67"
    viewBox="0 0 58 67"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>documents-outline</title>
    <g stroke="currentColor" fill="none" fillRule="evenodd">
      <path
        d="M40.08 34.498V58.56c0 3.402-2.667 6.16-6.013 6.16H8.02C4.667 64.72 2 61.962 2 58.56V21.6a6.196 6.196 0 0 1 6.16-6.16h13.177a3.437 3.437 0 0 1 2.448 1.03l15.281 15.54a3.556 3.556 0 0 1 1.014 2.488z"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M21.04 15.44v15.12a3.968 3.968 0 0 0 3.92 3.92h15.12"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.68 15.44V8.16A6.196 6.196 0 0 1 23.84 2H37a3.476 3.476 0 0 1 2.465 1.03l15.281 15.54a3.514 3.514 0 0 1 1.014 2.47v24.08c0 3.402-2.667 6.16-6.013 6.16H41.2"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M36.72 2v15.12a3.968 3.968 0 0 0 3.92 3.92h15.12"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 38a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 1 1 0 1.5h-4.5v4.5a.75.75 0 1 1-1.5 0v-4.5h-4.5a.75.75 0 1 1 0-1.5h4.5v-4.5a.75.75 0 0 1 .75-.75z"
        fill="currentColor"
      />
    </g>
  </svg>
);
