import styled from 'styled-components';

export const FileCard = styled.div`
  background-color: white;
  display: flex;
  font-weight: bold;
  padding: 1.5rem;
  position: relative;
  text-transform: uppercase;

  svg {
    color: #d9d9d9;
  }
`;

export const FileCardDelete = styled.button`
  background: none;
  border: none;
  bottom: 1rem;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 1rem;

  svg {
    pointer-events: none;
    display: block;
    color: #196267;
  }
`;
