import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --appPadding: 1rem;
    --maxWidth: 1500px;
    --minWidth: 1280px;
  }

  body {
    background-color: #eff0f0;
  }

  h1 {
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 3rem;
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1rem;

  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--colors-text-dark);
    letter-spacing: 0.067em;
  }

  h4 {
    color: #3f4848;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 700;
    color: var(--colors-text-dark);
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }

  h6 {
    color: #777777;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.067em;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  main {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--maxWidth);
    min-width: var(--minWidth);
    padding-left: var(--appPadding);
    padding-right: var(--appPadding);
    position: relative;
  }

  @media print {
    @page {
      size: Letter portrait;
    }

    html {
      font-size: 12px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: black;
    }
  }
`;
