import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from '@gohypergiant/scoa-smds';
import { get, filter } from 'lodash';
import {
  ActionBar,
  Layout,
  LayoutItem,
  ProgressIndicator,
  Step,
  TopBar,
} from '../../molecules';
import { FileDrop } from './file-drop';

const PROCESS_PACKAGE = gql`
  mutation beginDocumentPackageProcessing($id: ID!) {
    beginDocumentPackageProcessing(id: $id)
  }
`;

const GET_PACKAGE = gql`
  query getDocumentPackage($id: ID!) {
    documentPackage(id: $id) {
      id
      status
      documents {
        id
        status
        fileName
      }
    }
  }
`;

const Upload = ({ match }) => {
  const { packageId } = match.params;

  const {
    data: documentPackageData,
    loading: documentPackageLoading,
    error: documentPackageError,
    refetch,
  } = useQuery(GET_PACKAGE, {
    variables: {
      id: packageId,
    },
  });

  if (documentPackageLoading) {
    // TODO?
  }

  if (documentPackageError) {
    // TODO
  }

  const [
    processPackage,
    {
      loading: processPackageLoading,
      error: processPackageError,
      called: processPackageCalled,
    },
  ] = useMutation(PROCESS_PACKAGE);

  // NOTE: if the user has already clicked "Begin Scan" we don't want them to be able to
  // continue to upload documents
  const packageStatus = get(documentPackageData, 'documentPackage.status');

  if (packageStatus === 'PROCESSING' || packageStatus === 'COMPLETE') {
    return <Redirect to={`/${packageId}/scanning`} />;
  }

  const existingDocuments = filter(
    get(documentPackageData, 'documentPackage.documents', []),
    { status: 'UPLOADED' }
  );

  const documentCount = existingDocuments.length;
  const canProcess = documentCount > 0;

  // NOTE: this logic needs updated to check for a document package status as well
  const isScanning = processPackageCalled && !processPackageError;

  if (isScanning) {
    return <Redirect to={`/${packageId}/scanning`} />;
  }

  return (
    <Fragment>
      <TopBar />

      <main style={{ paddingBottom: '10rem' }}>
        <div style={{ color: 'inherit' }}>
          <ProgressIndicator>
            <Step active>Upload</Step>
            <Step>Scan</Step>
            <Step>Review</Step>
            <Step>Export</Step>
          </ProgressIndicator>
        </div>

        <h1>Upload Documents</h1>

        <Layout>
          <LayoutItem width={{ sm: '100% - 7rem' }}>
            <p>
              Upload PDF files containing any documents to be used as the{' '}
              <b>source of validation</b> and <b>need to be validated</b>.
            </p>
            <p>The documents may be combined into any number of PDF files.</p>
            <FileDrop
              packageId={packageId}
              onUpload={() => {
                refetch();
              }}
              existingDocuments={existingDocuments}
            />
          </LayoutItem>
        </Layout>
      </main>

      <ActionBar>
        <p style={{ marginBottom: 0 }}>
          <b>{documentCount} files</b> are ready to be scanned.
        </p>
        <Button
          style={{ width: '20rem' }}
          disabled={!canProcess || processPackageLoading}
          onClick={async () => {
            await processPackage({
              variables: {
                id: packageId,
              },
            });
          }}
        >
          Begin Scan
        </Button>
      </ActionBar>
    </Fragment>
  );
};

export default Upload;
