import styled from 'styled-components';

export const DocumentWrapper = styled.div`
  background-color: var(--colors-bg-dark);
  cursor: grab;
  overflow: hidden;
  position: relative;

  &:active {
    cursor: grabbing;
  }
`;
