import React, { Fragment, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles as GlobalSMDSStyles } from '@gohypergiant/scoa-smds';
import { client } from './apollo';
import { GlobalStyles } from './global/styles';
import { theme } from './theme';
import { UserProvider } from './contexts/user';
import { AuthenticatedRoutes } from 'atoms/auth-routes';

// NOTE: hot path routes should be statically imported for faster first paint
import Login from './pages/login';
import Init from './pages/init';
import Upload from './pages/upload';

import Scanning from './pages/scanning';

const Review = React.lazy(() => import('./pages/review'));
const ReviewDetail = React.lazy(() => import('./pages/review-detail'));
const NotProcessedDetail = React.lazy(() =>
  import('./pages/not-processed-detail')
);
const Export = React.lazy(() => import('./pages/export'));

const App = () => {
  return (
    <Fragment>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router>
            <GlobalSMDSStyles />
            <GlobalStyles />
            <UserProvider>
              <Suspense fallback={null}>
                <Switch>
                  <Route path="/login" exact component={Login} />

                  <AuthenticatedRoutes>
                    <Route path="/" exact component={Init} />
                    <Route path="/:packageId/upload" exact component={Upload} />
                    <Route
                      path="/:packageId/scanning"
                      exact
                      component={Scanning}
                    />
                    <Route
                      path="/:packageId/review/:reviewId"
                      component={ReviewDetail}
                    />
                    <Route
                      path="/:packageId/not-processed/:documentId"
                      component={NotProcessedDetail}
                    />
                    <Route path="/:packageId/review" exact component={Review} />
                    <Route path="/:packageId/export" exact component={Export} />
                  </AuthenticatedRoutes>

                  {/* NOTE: instead of 404 page we just redirect to / */}
                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </UserProvider>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>
  );
};

export default App;
