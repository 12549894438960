import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import Icon from '@iconify/react';
import personOutlineIcon from '@iconify/icons-ion/person-outline';
import logOutOutlineIcon from '@iconify/icons-ion/log-out-outline';

import { useUser } from 'hooks/user';

import {
  Logout,
  LogoWrapper,
  StyledLogo,
  StyledTopBar,
  Username,
  UserWrapper,
} from './styles';

export const TopBar = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { user, logout } = useUser();
  const userName = get(user, 'attributes.name', '');

  return (
    <StyledTopBar>
      <Link to="/" style={{ height: '100%' }}>
        <LogoWrapper>
          <StyledLogo theme="light" />
        </LogoWrapper>
      </Link>

      <UserWrapper onClick={() => setShowLogout(!showLogout)}>
        <Icon icon={personOutlineIcon} width="1.5rem" />
        <Username>{userName}</Username>
        {showLogout && (
          <Logout
            onClick={() => logout()}
            style={{
              opacity: showLogout ? 1 : 0,
            }}
          >
            Log Out
            <Icon icon={logOutOutlineIcon} width="1.5rem" />
          </Logout>
        )}
      </UserWrapper>
    </StyledTopBar>
  );
};
