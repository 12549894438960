import React from 'react';
import styled from 'styled-components';

const StyledScanning = styled.div`
  height: calc(100% - 8rem - 5rem);
  left: 0;
  position: fixed;
  top: 5rem;
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Scanning = () => {
  return (
    <StyledScanning>
      <video
        muted
        autoPlay
        loop
        playsInline
        src="https://doctr-cdn.vercel.app/scanning-loop.mp4"
        type="video/mp4"
      />
    </StyledScanning>
  );
};
