import styled from 'styled-components';

import { Logo } from '../../atoms';

export const StyledLoginWrapper = styled.div`
  align-items: center;
  background-color: #2d2e33;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const StyledBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgb(255, 255, 255), transparent);
    opacity: 0.75;
    mix-blend-mode: screen;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.65;
    z-index: 1;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(141, 182, 182));
  }

  video,
  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
    filter: brightness(1) grayscale(100);
  }
`;

// TODO: Split into separate component
export const Modal = styled.div`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  backdrop-filter: blur(1rem);
  background-color: rgb(255, 255, 255, 0.2);
  height: 36rem;
  margin: 1rem;
  max-width: 45rem;
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const ModalHeader = styled.div`
  margin-top: 6.25rem;
  margin-bottom: 5.25rem;
`;

export const StyledLogo = styled(Logo)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 18.875rem;
`;

export const ModalBody = styled.div`
  padding-left: 7.5rem;
  padding-right: 7.5rem;
`;

export const Error = styled.div`
  color: #ff0058;
  font-size: 14px;
  margin-top: 0.5rem;
  padding: 1rem;
  text-align: center;
`;
