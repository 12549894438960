import React from 'react';
import styled from 'styled-components';

const ActionBarWrapper = styled.div`
  background-color: white;
  bottom: 0;
  box-shadow: 0 -8px 16px 0 rgba(27, 31, 31, 0.14);
  height: 8rem;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media print {
    display: none;
  }
`;

const StyledActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--appPadding);
  padding-right: var(--appPadding);
  max-width: var(--maxWidth);
  min-width: var(--minWidth);
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

export const ActionBar = ({ children, ...props }) => {
  return (
    <ActionBarWrapper>
      <StyledActionBar {...props}>{children}</StyledActionBar>
    </ActionBarWrapper>
  );
};
