import styled, { css } from 'styled-components';
import { v } from '@gohypergiant/scoa-smds';

export const BoundingBox = styled.div`
  box-shadow: 0 0 0 2px currentColor;
  cursor: pointer;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  transition-duration: 50ms;
  transition-property: border-width;
  transition-timing-function: ease-in-out;
  z-index: 10;

  @keyframes attention {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  ${({ variant }) =>
    variant &&
    css`
      color: ${v('colors.functional.' + variant)};
    `}

  ${({ active }) =>
    active &&
    css`
      animation-duration: 200ms;
      animation-name: attention;
      animation-timing-function: ease-in;
      box-shadow: 0 0 0 3px currentColor;
      opacity: 0.8;
    `}
`;
