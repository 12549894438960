import styled, { css } from 'styled-components';

export const Zoom = styled.div`
  align-items: center;
  background-color: var(--colors-text-dark);
  bottom: 0;
  color: white;
  display: flex;
  font-size: 0.625rem;
  height: 2rem;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 8rem;
`;

export const ZoomButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  height: 100%;
  outline: none;
  padding: 0;
  text-align: center;
  width: 2.25rem;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;

export const ZoomValue = styled.div`
  border-left: 1px solid var(--colors-text-muted);
  border-right: 1px solid var(--colors-text-muted);
  cursor: pointer;
  flex-shrink: 0;
  height: 100%;
  line-height: 2rem;
  text-align: center;
  user-select: none;
  width: 3.5rem;
`;
